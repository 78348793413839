@tailwind base;
@font-face {
  font-family: "manrope" !important;
  src: local("Manrope"), url(../assets/fonts/Manrope-Regular.ttf) format("ttf");
}
@tailwind components;
@tailwind utilities;

@layer components {
  body{
        font-family: 'manrope',sans-serif
    }
  /* Layout classes start */
  .mainContainer {
    @apply w-screen;
  }
  .customContainer {
    @apply w-5/6 mx-auto;
    max-width: 1320px;
  }
  .row {
    @apply flex w-full flex-wrap;
  }
  .col {
    @apply flex-1;
  }
  .col_1 {
    @apply w-1/12;
  }
  .col_2 {
    @apply w-2/12;
  }
  .col_3 {
    @apply w-3/12;
  }
  .col_4 {
    @apply w-4/12;
  }
  .col_5 {
    @apply w-5/12;
  }
  .col_6 {
    @apply w-6/12;
  }
  .col_7 {
    @apply w-7/12;
  }
  .col_8 {
    @apply w-8/12;
  }
  .col_9 {
    @apply w-9/12;
  }
  .col_10 {
    @apply w-10/12;
  }
  .col_11 {
    @apply w-11/12;
  }
  .col_12 {
    @apply w-full;
  }
  /* Layout classes end */
  /* Common Components Start */
  /* Button Start */
  .button {
    @apply bg-primary rounded-lg py-2.5 px-6 text-white font-bold;
  }
  .button:disabled{
    @apply bg-opacity-40 cursor-default border-opacity-40;
  }
  .button--success {
    @apply bg-none  border-2 border-success text-success;
  }
  .button--danger {
    @apply bg-none  border-2 text-darkred border-darkred;
  }
  .button--smallRound{
    @apply rounded-full border-2 border-textDisabled h-8 w-8 text-center mx-2;
  }
  
  /* Button End */

  /* InputField Start */
  .inputField {
  }
  .inputField__label {
    @apply block capitalize text-textlight text-sm py-2;
  }
  .inputField__field {
    @apply border rounded-lg px-3 py-2 w-full;
  }
  .inputField__error {
    @apply text-darkred text-xs py-1.5;
  }
  .input__radio{
    @apply relative mx-1;
  }
  input:disabled{
    @apply text-textDisabled
  }
  .input__radio:checked::after{
    content:"";
    @apply absolute top-0 left-0 w-full h-full z-10 bg-primary rounded-full;
  }
  /* InputField End */
  .card {
    @apply bg-primary rounded-lg p-5 text-white h-full cursor-pointer;
  }
  .card__container{
    @apply col_5 p-2
    desktop:w-4/12;
  }
  .card--graph{
    @apply bg-white rounded-br-lg p-4
  }
  /* Common Components end */
  /* Table Start */
  .primarytable th,
  .primarytable td {
    @apply px-11 py-4;
  }
  .primarytable tfoot {
    @apply font-bold bg-darkgrey;
  }

  .popupTable th,
  .popupTable td,
  .popupTable__data {
    @apply py-4 px-2.5 overflow-hidden
    desktop:px-4;
    min-width: 121px;
  }

  .popupTable thead, .popupTable__head {
    @apply border-b-2 border-darkgrey;
    width: fit-content;
  }
  .popupTable tfoot,
  .popupTable__foot {
    @apply font-bold;
  }
  .popupTable input{
    @apply w-full border-b border-primaryfooter outline-none
  }
  .popupTable input:focus{
    @apply outline-none border-b border-primaryfooter
  }
  .popupTable input:disabled{
    @apply text-black bg-transparent border-0
  }
  /* Table End */

  /* popup Start */
  .popupouter {
    @apply fixed top-0 left-0 min-h-screen w-screen bg-black bg-opacity-20 row items-center;
  }
  .popupContainer {
    @apply bg-grey col_8 mx-auto pt-4 rounded-2xl relative overflow-hidden z-10 text-black
    desktop:w-6/12;
  }
  .popupHeader {
    @apply font-bold py-3 bg-grey px-4 text-textprimary;
  }
  .popupCross {
    @apply absolute top-4 right-4;
  }
  /* popup End */

  /* New listing start */
  .newListing {
    @apply bg-grey min-h-screen;
  }
  .stepper__button {
    @apply font-bold py-12;
  }
  .stepper__count {
    @apply text-textDisabled rounded-full py-3 w-12 text-center mx-auto border;
  }
  .stepper__text {
    @apply text-textDisabled text-base py-3;
  }
  .stepper__button--active .stepper__count {
    @apply bg-primary text-white;
  }
  .stepper__button--active .stepper__text {
    @apply text-primary;
  }
  .dropdown {
    @apply border rounded-lg px-4 bg-white relative;
  }
  .dropdown__field {
    @apply w-full bg-transparent py-2 text-sm outline-none;
  }
  .dropdown__menu {
    @apply hidden bg-white border rounded-lg py-2 absolute top-full left-0 w-full z-50 mt-1 shadow-md;
  }
  .dropdown__field:focus ~ .dropdown__menu {
    @apply block;
  }
  .dropdown__field:not(:placeholder-shown) ~ .dropdown__menu {
    @apply hidden;
  }
  .dropdown__menu:hover{
    @apply block
  }
  /* .dropdown__field::after{
      content:"\f0d7";
      @apply absolute top-0 right-0 w-10 h-10 z-50 border-2 bg-red;
      top:calC(50% - 4px);
  } */
  .listAccordion{
      @apply text-sm;
  }
  .listAccordion:not(:last-of-type){
      @apply border-b;
  }
  .listAccordion__outer {
      @apply py-2 font-bold;
  }
  .listAccordion__inner {
    @apply hidden;
  }
  .listAccordion:hover .listAccordion__inner {
    @apply block;
  }

  /* List Loader Start */
  .listLoader{
    @apply col_4 mx-auto shadow-lg text-center p-5 rounded-lg;
  }
.listLoader__img{
@apply w-12 h-12;
}
.listLoader__text{
@apply py-2 text-base text-subtext font-bold;
}
  /* List Loader End */

  /* Qualify Check List start */
  .qualifyCheckListTable{
    @apply w-full py-2 bg-white rounded-br-lg rounded-bl-lg;
  }
  .qualifyCheckListTable th, .qualifyCheckListTable td{
    @apply py-5 px-3 font-semibold text-sm;
  }
  .qualifyCheckListTable tr:not(:last-of-type),.qualifyCheckListTable thead{
    @apply border-b
  }
  .qualifyCheckListTable th{
    @apply text-base;
  }
  /* Qualify Check List end */
  /* New listing end */
  /* Header Start */
  .header__menu{
    @apply mx-3 relative;
  }
  .header__submenu li:not(:last-child){
    @apply border-b-2
  }
  .header__submenu{
@apply hidden absolute top-full right-0 bg-white pt-3 shadow-sm rounded-lg p-4 w-44 font-bold;
  }
  .header__submenu button{
    @apply font-bold
  }
  .header__menu:hover .header__submenu{
    @apply block;
  }
  /* Header End */
  /* New Orders start */
  .newOrderList{
    @apply overflow-auto border max-h-40;
    
  }
  /* New Orders end */
  /* Chart JS start */
  .canvasjs-chart-credit{
    @apply hidden
  }
  /* Chart JS End */
  /* uploaded list start */
  .productOuter{
    @apply max-h-40 overflow-auto bg-red;
  }
  /* uploaded list end */
}


/*     fonts      */

.f_14{
    font: normal normal 300 14px Manrope;
    color: #242424;
}

.f_16_bold{
    font: normal normal bold 16px Manrope;
    color: #242424;
}