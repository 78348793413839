.tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    margin-top: 12px;
  }
  
  .tooltip::before {
    content: '';
    position: absolute;
    top: -30%;
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }
  .tooltip-button:hover + .tooltip {
    visibility: visible;
    opacity: 1;
  }